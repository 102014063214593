<template>
  <div class="wxmain">
    <el-form
      :inline="true"
      ref="searchWrapper"
      label-position="right"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-form-item :label="$t('searchModule.Merchant_Name')">
        <el-select v-model.trim="formInline.operationId" filterable size="15">
          <el-option label="全部" value=""></el-option>
          <el-option
            :label="value.operationName"
            :value="value.operationId"
            :key="value.operationId"
            v-for="value in roleList"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="appid" prop="appidSearch">
        <el-input size="12" v-model="formInline.appidSearch" placeholder="请输入appid"></el-input>
      </el-form-item>
    </el-form>
    <el-row :gutter="24">
      <el-col :span="8" class="boxMenu">
        <el-form
          :model="menuOne"
          :rules="rules"
          ref="menuOne"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="菜单一名称" prop="name">
            <el-input v-model="menuOne.name" style="width: 60%" maxlength="8"></el-input>
            <span class="sunMenu" @click="addSonMenu" v-if="menuOne.type == 'view'"
              >添加子菜单</span
            >
          </el-form-item>
          <el-form-item label="菜单一类型" prop="type">
            <el-select
              v-model="menuOne.type"
              placeholder="请选择菜单类型"
              style="width: 60%"
              @change="rootTypeChange"
            >
              <el-option
                :label="value.name"
                :value="value.type"
                :key="value.type"
                v-for="value in menuTypeList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="getLabel(menuOne).label"
            v-if="menuOne.sub_button.length == 0"
            prop="url"
          >
            <el-input
              v-model="menuOne.url"
              style="width: 60%"
              :placeholder="getLabel(menuOne).placeholder"
            ></el-input>
          </el-form-item>
          <el-form-item label="小程序路径" v-if="menuOne.type == 'miniprogram'" prop="pagepath">
            <el-input
              v-model="menuOne.pagepath"
              style="width: 60%"
              placeholder="请输入小程序路径"
            ></el-input>
          </el-form-item>
          <el-form-item label="小程序appid" v-if="menuOne.type == 'miniprogram'" prop="appid">
            <el-input
              v-model="menuOne.appid"
              style="width: 60%"
              placeholder="请输入小程序appid"
            ></el-input>
          </el-form-item>
          <!-- 子菜单 -->
          <div v-for="(item, index) in menuOne.sub_button" :key="index">
            <el-form-item
              :label="item.itemTitle"
              :prop="`sub_button[${index}].name`"
              :rules="ruleStr60"
            >
              <el-input v-model="item.name" style="width: 60%"></el-input>
              <span class="sunMenu" @click="deletaSonMenu(index)">删除子菜单</span>
            </el-form-item>
            <el-form-item label="菜单类型" prop="type">
              <el-select v-model="item.type" placeholder="请选择菜单类型" style="width: 60%">
                <el-option
                  :label="value.name"
                  :value="value.type"
                  :key="value.type"
                  v-for="value in menuTypeList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="getLabel(item).label"
              :prop="`sub_button[${index}].url`"
              :rules="ruleStr"
            >
              <el-input
                v-model="item.url"
                style="width: 60%"
                :placeholder="getLabel(item).placeholder"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="小程序路径"
              v-if="item.type == 'miniprogram'"
              :prop="`sub_button[${index}].pagepath`"
              :rules="ruleStr"
            >
              <el-input
                v-model="item.pagepath"
                style="width: 60%"
                placeholder="请输入小程序路径"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="小程序appid"
              v-if="item.type == 'miniprogram'"
              :prop="`sub_button[${index}].appid`"
              :rules="ruleStr"
            >
              <el-input
                v-model="item.appid"
                style="width: 60%"
                placeholder="请输入小程序appid"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </el-col>
      <!-- 菜单二 -->
      <el-col :span="8" class="boxMenu">
        <el-form
          :model="menuTwo"
          :rules="rules"
          ref="menuTwo"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="菜单二名称" prop="name">
            <el-input v-model="menuTwo.name" style="width: 60%" maxlength="8"></el-input>
            <span class="sunMenu" @click="addSonMenuTwo" v-if="menuTwo.type == 'view'"
              >添加子菜单</span
            >
          </el-form-item>
          <el-form-item label="菜单二类型" prop="type">
            <el-select
              v-model="menuTwo.type"
              placeholder="请选择菜单类型"
              style="width: 60%"
              @change="rootTypeChange2"
            >
              <el-option
                :label="value.name"
                :value="value.type"
                :key="value.type"
                v-for="value in menuTypeList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="getLabel(menuTwo).label"
            v-if="menuTwo.sub_button.length == 0"
            prop="url"
          >
            <el-input
              v-model="menuTwo.url"
              style="width: 60%"
              :placeholder="getLabel(menuTwo).placeholder"
            ></el-input>
          </el-form-item>
          <el-form-item label="小程序路径" v-if="menuTwo.type == 'miniprogram'" prop="pagepath">
            <el-input
              v-model="menuTwo.pagepath"
              style="width: 60%"
              placeholder="请输入小程序路径"
            ></el-input>
          </el-form-item>
          <el-form-item label="小程序appid" v-if="menuTwo.type == 'miniprogram'" prop="appid">
            <el-input
              v-model="menuTwo.appid"
              style="width: 60%"
              placeholder="请输入小程序appid"
            ></el-input>
          </el-form-item>
          <!-- 子菜单 -->
          <div v-for="(item, index) in menuTwo.sub_button" :key="index">
            <el-form-item
              :label="item.itemTitle"
              :prop="`sub_button[${index}].name`"
              :rules="ruleStr60"
            >
              <el-input v-model="item.name" style="width: 60%"></el-input>
              <span class="sunMenu" @click="deletaSonMenuTwo(index)">删除子菜单</span>
            </el-form-item>
            <el-form-item label="菜单类型" prop="type">
              <el-select v-model="item.type" placeholder="请选择菜单类型" style="width: 60%">
                <el-option
                  :label="value.name"
                  :value="value.type"
                  :key="value.type"
                  v-for="value in menuTypeList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="getLabel(item).label"
              :prop="`sub_button[${index}].url`"
              :rules="ruleStr"
            >
              <el-input
                v-model="item.url"
                style="width: 60%"
                :placeholder="getLabel(item).placeholder"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="小程序路径"
              v-if="item.type == 'miniprogram'"
              :prop="`sub_button[${index}].pagepath`"
              :rules="ruleStr"
            >
              <el-input
                v-model="item.pagepath"
                style="width: 60%"
                placeholder="请输入小程序路径"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="小程序appid"
              v-if="item.type == 'miniprogram'"
              :prop="`sub_button[${index}].appid`"
              :rules="ruleStr"
            >
              <el-input
                v-model="item.appid"
                style="width: 60%"
                placeholder="请输入小程序appid"
              ></el-input>
            </el-form-item>
          </div>
          <div class="footerButton">
            <el-button type="primary" @click="submitForm()">{{ $t('button.preservation') }}</el-button>
            <el-button @click="resetForm()">{{ $t('button.reset') }}</el-button>
          </div>
        </el-form>
      </el-col>
      <!-- 菜单三 -->
      <el-col :span="8" class="boxMenu">
        <el-form
          :model="menuThree"
          :rules="rules"
          ref="menuThree"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="菜单三名称" prop="name">
            <el-input v-model="menuThree.name" style="width: 60%" maxlength="8"></el-input>
            <span class="sunMenu" @click="addSonMenuThree" v-if="menuThree.type == 'view'"
              >添加子菜单</span
            >
          </el-form-item>
          <el-form-item label="菜单三类型" prop="type">
            <el-select
              v-model="menuThree.type"
              placeholder="请选择菜单类型"
              style="width: 60%"
              @change="rootTypeChange3"
            >
              <el-option
                :label="value.name"
                :value="value.type"
                :key="value.type"
                v-for="value in menuTypeList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="getLabel(menuThree).label"
            v-if="menuThree.sub_button.length == 0"
            prop="url"
          >
            <el-input
              v-model="menuThree.url"
              style="width: 60%"
              :placeholder="getLabel(menuThree).placeholder"
            ></el-input>
          </el-form-item>
          <el-form-item label="小程序路径" v-if="menuThree.type == 'miniprogram'" prop="pagepath">
            <el-input
              v-model="menuThree.pagepath"
              style="width: 60%"
              placeholder="请输入小程序路径"
            ></el-input>
          </el-form-item>
          <el-form-item label="小程序appid" v-if="menuThree.type == 'miniprogram'" prop="appid">
            <el-input
              v-model="menuThree.appid"
              style="width: 60%"
              placeholder="请输入小程序appid"
            ></el-input>
          </el-form-item>
          <!-- 子菜单 -->
          <div v-for="(item, index) in menuThree.sub_button" :key="index">
            <el-form-item
              :label="item.itemTitle"
              :prop="`sub_button[${index}].name`"
              :rules="ruleStr60"
            >
              <el-input v-model="item.name" style="width: 60%"></el-input>
              <span class="sunMenu" @click="deletaSonMenuThree(index)">删除子菜单</span>
            </el-form-item>
            <el-form-item label="菜单类型" prop="type">
              <el-select v-model="item.type" placeholder="请选择菜单类型" style="width: 60%">
                <el-option
                  :label="value.name"
                  :value="value.type"
                  :key="value.type"
                  v-for="value in menuTypeList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="getLabel(item).label"
              :prop="`sub_button[${index}].url`"
              :rules="ruleStr"
            >
              <el-input
                v-model="item.url"
                style="width: 60%"
                :placeholder="getLabel(item).placeholder"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="小程序路径"
              v-if="item.type == 'miniprogram'"
              :prop="`sub_button[${index}].pagepath`"
              :rules="ruleStr"
            >
              <el-input
                v-model="item.pagepath"
                style="width: 60%"
                placeholder="请输入小程序路径"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="小程序appid"
              v-if="item.type == 'miniprogram'"
              :prop="`sub_button[${index}].appid`"
              :rules="ruleStr"
            >
              <el-input
                v-model="item.appid"
                style="width: 60%"
                placeholder="请输入小程序appid"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleStr: [
        { required: true, message: "请输入正确的内容", trigger: "blur" },
        {
          min: 1,
          max: 64,
          message: "长度在 1 到 64 个字符",
          trigger: "blur",
        },
      ],
      ruleStr60: [
        { required: true, message: "请输入正确的内容", trigger: "blur" },
        {
          min: 1,
          max: 8,
          message: "长度在 1 到 8 个字符",
          trigger: "blur",
        },
      ],
      formInline: {
        operationId: "",
        appidSearch: "",
      },
      clickResponse: "",
      menuJson: "",
      roleList: [],
      menuOne: {
        name: "",
        type: "view",
        url: "",
        appid: "",
        pagepath: "",
        sub_button: [],
      },
      menuTwo: {
        name: "",
        type: "view",
        url: "",
        appid: "",
        pagepath: "",
        sub_button: [],
      },
      menuThree: {
        name: "",
        type: "view",
        url: "",
        appid: "",
        pagepath: "",
        sub_button: [],
      },

      menuTypeList: [
        { name: "页面跳转", type: "view" },
        { name: "消息回复", type: "click" },
        { name: "小程序跳转", type: "miniprogram" },
      ],
      rules: {
        name: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
          {
            min: 1,
            max: 4,
            message: "长度在 1 到 4 个字符",
            trigger: "blur",
          },
        ],
        // 'sub_button[0].name': [
        //   { required: true, message: "请输入菜单名称", trigger: "blur" },
        //   {
        //     min: 1,
        //     max: 16,
        //     message: "长度在 1 到 16 个字符",
        //     trigger: "blur",
        //   },
        // ],
        type: [{ required: true, message: "请选择菜单类型", trigger: "change" }],
        url: [
          { required: true, message: "请输入正确的内容", trigger: "blur" },
          {
            min: 1,
            max: 512,
            message: "应输入1到512个字符",
            trigger: "blur",
          },
        ],
        pagepath: [
          { required: true, message: "请输入正确的内容", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        appid: [
          { required: true, message: "请输入正确的内容", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getRoleList();
  },
  methods: {
    rootTypeChange(value) {
      if (value != 1) {
        this.menuOne.sub_button = [];
      }
      this.menuOne.url = "";
    },
    rootTypeChange2(value) {
      if (value != 1) {
        this.menuTwo.sub_button = [];
      }
      this.menuTwo.url = "";
    },
    rootTypeChange3(value) {
      if (value != 1) {
        this.menuThree.sub_button = [];
      }
      this.menuThree.url = "";
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    submitForm() {
      if (this.formInline.operationId == "") {
        this.$alert("商户为必填", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.formInline.appidSearch == "") {
        this.$alert("Appid为必填", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let subContont = 0;
      this.$refs["menuOne"].validate((valid) => {
        if (valid) {
          subContont += 1;
        }
      });
      this.$refs["menuTwo"].validate((valid) => {
        if (valid) {
          subContont += 1;
        }
      });
      this.$refs["menuThree"].validate((valid) => {
        if (valid) {
          subContont += 1;
        }
      });
      console.log("subContont-->", subContont);
      if (subContont != 3) {
        this.$alert("请填写将信息填写完整", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let menuJson = {};
      menuJson.button = [];
      let clickResponse = {};
      menuJson.button.push(this.menuOne);
      menuJson.button.push(this.menuTwo);
      menuJson.button.push(this.menuThree);
      for (let itemKey in menuJson.button) {
        // console.log("menuJson.button[itemKey]-》", menuJson.button[itemKey]);
        if (menuJson.button[itemKey].type == "click") {
          menuJson.button[itemKey].key = this.formInline.operationId + "-button" + itemKey;
          clickResponse[menuJson.button[itemKey].key] = menuJson.button[itemKey].url;
        }
        let subbutton = menuJson.button[itemKey].sub_button;
        if (subbutton.url == "") {
          subbutton.url = "http://";
        }
        for (let sonItem in subbutton) {
          if (subbutton[sonItem].type == "click") {
            subbutton[sonItem].key = this.formInline.operationId + "-" + subbutton[sonItem].key;
            clickResponse[subbutton[sonItem].key] = subbutton[sonItem].url;
          }
        }
      }
      this.clickResponse = clickResponse;
      this.menuJson = menuJson;
      console.log("拼接完成menuJson-》", menuJson);
      console.log("拼接完成clickResponse-》", clickResponse);
      this.$confirm("确定保存信息？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.submit();
        })
        .catch(() => {});
    },
    submit() {
      // let menuStr = {}
      // menuStr.menu = this.menuJson
      // menuStr.clickResponse = this.clickResponse
      // console.log("menuStr", menuStr);
      let obj = {};
      for (let key in this.clickResponse) {
        if (key.split("-").length > 2) {
          let k = key.split("-")[1] + "-" + key.split("-")[2];
          obj[k] = this.clickResponse[key];
        } else {
          obj[key] = this.clickResponse[key];
        }
      }
      this.$axios
        .post(
          "/acb/2.0/member/menu/create?operationId=" +
            this.formInline.operationId +
            "&appId=" +
            this.formInline.appidSearch,
          {
            data: {
              // operationId: this.formInline.operationId,
              // appId: this.formInline.appidSearch,

              menu: this.menuJson,
              clickResponse: obj, // this.clickResponse,
            },
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
        .then((res) => {
          if (res.state == 0) {
            this.$alert(res.value, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            this.resetForm();
          }
        });
    },
    getType(type) {
      let typeStr = "";
    },
    resetForm() {
      this.menuOne = {
        name: "",
        type: "view",
        url: "",
        sub_button: [],
      };
      this.menuTwo = {
        name: "",
        type: "view",
        url: "",
        sub_button: [],
      };
      this.menuThree = {
        name: "",
        type: "view",
        url: "",
        sub_button: [],
      };
    },
    getLabel(menuItem) {
      let dataStr = {};
      if (menuItem.type == "view") {
        dataStr.label = "页面跳转";
        dataStr.placeholder = "请输入页面跳转地址";
      } else if (menuItem.type == "click") {
        dataStr.label = "消息回复";
        dataStr.placeholder = "请输入消息回复内容";
      } else if (menuItem.type == "miniprogram") {
        dataStr.label = "小程序url";
        dataStr.placeholder = "请输入小程序url";
      }
      return dataStr;
    },
    addSonMenu() {
      if (this.menuOne.sub_button.length == 5) {
        this.$alert("最多支持五个菜单", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.menuOne.url = "http://";
      let itemTitle = "子菜单" + this.toChinese(this.menuOne.sub_button.length + 1);
      let itemKey = "itemKeyOne" + this.menuOne.sub_button.length;
      this.menuOne.sub_button.push({
        name: "",
        type: "view",
        url: "",
        itemTitle: itemTitle,
        appid: "",
        pagepath: "",
        key: itemKey,
      });
    },
    deletaSonMenu(index) {
      this.menuOne.sub_button.splice(index, 1);
      console.log("this.menuOne", this.menuOne);
    },
    addSonMenuTwo() {
      if (this.menuTwo.sub_button.length == 5) {
        this.$alert("最多支持五个菜单", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.menuTwo.url = "http://";
      let itemTitle = "子菜单" + this.toChinese(this.menuTwo.sub_button.length + 1);
      let itemKey = "itemKeyTwo" + this.menuOne.sub_button.length;
      this.menuTwo.sub_button.push({
        name: "",
        type: "view",
        url: "",
        itemTitle: itemTitle,
        key: itemKey,
      });
    },
    deletaSonMenuTwo(index) {
      this.menuTwo.sub_button.splice(index, 1);
      console.log("this.menuTwo", this.menuTwo);
    },
    addSonMenuThree() {
      if (this.menuThree.sub_button.length == 5) {
        this.$alert("最多支持五个菜单", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      this.menuThree.url = "http://";
      let itemTitle = "子菜单" + this.toChinese(this.menuThree.sub_button.length + 1);
      let itemKey = "itemKeyThree" + this.menuOne.sub_button.length;
      this.menuThree.sub_button.push({
        name: "",
        type: "view",
        url: "",
        itemTitle: itemTitle,
        key: itemKey,
      });
    },
    deletaSonMenuThree(index) {
      this.menuThree.sub_button.splice(index, 1);
      console.log("this.menuThree", this.menuThree);
    },
    toChinese(values) {
      let chinLlist = ["一", "二", "三", "四", "五"];
      let sn = parseInt(values);
      return chinLlist[sn - 1];
    },
    getList() {},
    save() {},
  },
};
</script>

<style scoped>
.wxmain {
  padding-top: 20px;
}

.sunMenu {
  color: blue;
  padding-left: 10px;
  cursor: pointer;
}

.boxMenu {
  border-right: 1px solid #808080;
  min-height: 100vh;
  background-color: #fff;
  padding: 10px;
}
.footerButton {
  text-align: center;
}
</style>
